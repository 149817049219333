<template>
  <v-dialog v-model="dialog" persistent max-width="350">
    <v-card>
      <v-system-bar window color="white">
        <v-spacer></v-spacer>
        <v-btn small icon color="primary" @click="dialog = false">
          <v-icon color="primary">mdi-close</v-icon>
        </v-btn>
      </v-system-bar>

      <v-card-text
        class="black--text d-flex flex-column text-center justify-center pt-1 pb-8"
      >
        <div class="mb-2">
          <v-icon large color="error">mdi-close-circle-outline</v-icon>
        </div>
        <div>
          <span class="pt-1" v-html="message"></span>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ErrorDialog",
  data: () => ({
    dialog: false,
    message: "",
  }),
  created() {
    // eslint-disable-next-line no-undef
    Fire.$on("ErrorDialog", (message) => {
      this.dialog = true;
      this.message = message;
    });
  },
};
</script>
