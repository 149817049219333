<template>
  <v-data-table
    dense
    :headers="headers"
    :items="interviews"
    item-key="name"
    class="elevation-1"
    @click:row="goToInterview"
  >
    <template v-slot:[`item.client`]="{ item }">
      {{ item.vacancy.client ? item.vacancy.client.name : "" }}
    </template>
    <template v-slot:[`item.vacancy`]="{ item }">
      {{ item.vacancy.title }}
    </template>
    <template v-slot:[`item.interviewers`]="{ item }">
      <avatar-group
        :items="avatarGroups(recrutersAndObservers(item).recruters)"
      />
    </template>
    <template v-slot:[`item.observers`]="{ item }">
      <avatar-group
        :items="avatarGroups(recrutersAndObservers(item).observers)"
      />
    </template>
    <template v-slot:[`item.candidates`]="{ item }">
      {{ totalCandidates(item) }}
    </template>
    <template v-slot:[`item.date`]="{ item }">
      {{ formatDate(item) }}
    </template>
    <template v-slot:[`item.status`]="{ item }">
      <v-btn tile x-small dense :color="`${status(item).color} white--text`">
        {{ status(item).text }}
      </v-btn>
    </template>
  </v-data-table>
</template>

<script>
import { format } from "date-fns";
import AvatarGroup from "../components/recruter/AvatarGroupTable.vue";

export default {
  name: "InterviewTable",

  props: ["interviews"],

  components: {
    AvatarGroup,
  },

  data: () => ({
    desserts: [
      {
        name: "Frozen Yogurt",
        calories: 159,
        fat: 6.0,
        carbs: 24,
        protein: 4.0,
        iron: 1,
      },
      {
        name: "Ice cream sandwich",
        calories: 237,
        fat: 9.0,
        carbs: 37,
        protein: 4.3,
        iron: 1,
      },
      {
        name: "Eclair",
        calories: 262,
        fat: 16.0,
        carbs: 23,
        protein: 6.0,
        iron: 7,
      },
      {
        name: "Cupcake",
        calories: 305,
        fat: 3.7,
        carbs: 67,
        protein: 4.3,
        iron: 8,
      },
      {
        name: "Gingerbread",
        calories: 356,
        fat: 16.0,
        carbs: 49,
        protein: 3.9,
        iron: 16,
      },
      {
        name: "Jelly bean",
        calories: 375,
        fat: 0.0,
        carbs: 94,
        protein: 0.0,
        iron: 0,
      },
      {
        name: "Lollipop",
        calories: 392,
        fat: 0.2,
        carbs: 98,
        protein: 0,
        iron: 2,
      },
      {
        name: "Honeycomb",
        calories: 408,
        fat: 3.2,
        carbs: 87,
        protein: 6.5,
        iron: 45,
      },
      {
        name: "Donut",
        calories: 452,
        fat: 25.0,
        carbs: 51,
        protein: 4.9,
        iron: 22,
      },
      {
        name: "KitKat",
        calories: 518,
        fat: 26.0,
        carbs: 65,
        protein: 7,
        iron: 6,
      },
    ],
  }),

  computed: {
    headers() {
      return [
        {
          text: this.$t("Job_client"),
          align: "start",
          sortable: false,
          value: "client",
        },
        { text: this.$t("vacancy_title"), value: "vacancy" },
        { text: this.$t("interviewers"), value: "interviewers" },
        { text: this.$t("observers"), value: "observers" },
        { text: this.$t("candidates"), value: "candidates" },
        { text: this.$t("date"), value: "date" },
        { text: this.$t("status"), value: "status" },
      ];
    },
  },

  methods: {
    goToInterview(interview) {
      this.$router.push({
              name: 'scheduled.interviews',
              params: { id: interview.id },
            });
      // this.$router.push({
      //   name: "interviews.preview",
      //   params: { id: interview.id },
      // });
    },

    totalCandidates(interview) {
      return interview.candidates.length;
    },

    avatarGroups(recruters) {
      return recruters.map((mapRecruter) => ({
        id: mapRecruter.user.id,
        name: mapRecruter.user.name,
        photo: mapRecruter.user.photo,
      }));
    },

    recrutersAndObservers(interview) {
      const TOTAL_CANDIDATES = interview.candidates.length;
      const CANDIDATES = interview.candidates;
      let candidateIndex = 0;
      let recruters = [];
      let observers = [];

      for (
        candidateIndex;
        candidateIndex < TOTAL_CANDIDATES;
        candidateIndex++
      ) {
        const STAGES = CANDIDATES[candidateIndex].stages;
        const findedStage = STAGES.find(
          (findStage) =>
            findStage.name === CANDIDATES[candidateIndex].currentStage
        );
        recruters = [...recruters, ...findedStage.recruters];

        if (findedStage.observers) {
          observers = [...observers, ...findedStage.observers];
        }
      }

      recruters = recruters.filter((findRecruter, index) => {
        const recruterIds = recruters.map((mapRecruter) => mapRecruter.user.id);

        return recruterIds.indexOf(findRecruter.user.id) === index;
      });
      observers = observers.filter((findObserver, index) => {
        const observerIds = observers.map((mapObserver) => mapObserver.user.id);

        return observerIds.indexOf(findObserver.user.id) === index;
      });

      return { recruters, observers };
    },

    formatDate(interview) {
      const FIRST_ELEMENT = 0;
      const currentLang = localStorage.getItem("lang")
        ? localStorage.getItem("lang")
        : "pt";
      const DATE_OF_FIRST_INTERVIEW = new Date(
        interview.candidates[FIRST_ELEMENT].stages[FIRST_ELEMENT].date
      );
      let dateAndTime;

      switch (currentLang) {
        case "en":
          dateAndTime = format(DATE_OF_FIRST_INTERVIEW, "yyyy/MM/dd");
          break;

        default:
          dateAndTime = format(DATE_OF_FIRST_INTERVIEW, "dd/MM/yyyy");
          break;
      }

      return dateAndTime;
    },

    status(interview) {
      let states = [];
      const CANDIDATES = interview.candidates;
      CANDIDATES.forEach((mapCandidate) => {
        if (mapCandidate.status !== "pending") {
          states.push(mapCandidate.status);
        } else {
          mapCandidate.stages.forEach((mapStage) =>
            states.push(mapStage.state)
          );
        }
      });

      const STATUS = states;
      const TOTAL_STATUS = STATUS.length;
      let statusIndex = 0;
      let pending = 0,
        running = 0,
        canceled = 0,
        ongoing = 0,
        completed = 0;
      const PENDING_STATUS = { text: this.$t("pending"), color: "cyan" };
      const ONGOING_STATUS = { text: this.$t("ongoing"), color: "warning" };
      const CANCELED_STATUS = { text: this.$t("canceled"), color: "error" };
      const FINISHED_STATUS = { text: this.$t("completed"), color: "success" };

      for (statusIndex; statusIndex < TOTAL_STATUS; statusIndex++) {
        switch (STATUS[statusIndex]) {
          case "pending":
            pending += 1;
            break;

          case "rejected":
          case "approved":
            completed += 1;
            break;

          case "canceled":
            canceled += 1;
            break;

          case "ongoing":
          case "running":
          case "pause":
          case "finished":
            ongoing += 1;
            break;

          default:
            running += 1;
            break;
        }
      }

      if (TOTAL_STATUS === pending) {
        return PENDING_STATUS;
      } else if (TOTAL_STATUS === ongoing || ongoing >= 1 || running >= 1) {
        return ONGOING_STATUS;
      } else if (TOTAL_STATUS === canceled) {
        return CANCELED_STATUS;
      } else if (
        TOTAL_STATUS === completed ||
        completed + canceled === TOTAL_STATUS
      ) {
        return FINISHED_STATUS;
      }

      return PENDING_STATUS;
    },
  },
};
</script>